/* eslint-disable camelcase */
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import getLocalizedUrl from 'lib/utils/getLocalizedUrl';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHrefLangData } from 'redux/features/globalData-slice';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const useGenerateErrorPageHreflangs = () => {
  const appNavigator = useAppNavigator();
  const dispatch = useDispatch();

  const generateErrorPageHreflangs = () => {
    let canonicalLink = '';

    const hrefLangs = Object.entries(mapNamedLocaleToLocale).map(
      ([namedLocale, standardLocale]) => {
        const url = appNavigator.asPath;
        if (namedLocale === appNavigator.locale) {
          canonicalLink = getLocalizedUrl(url, namedLocale);
        }
        return {
          url: getLocalizedUrl(url, namedLocale),
          hreflang: standardLocale === 'en' ? 'x-default' : standardLocale,
        };
      },
    );
    return {
      canonical: canonicalLink,
      urls: hrefLangs,
    };
  };

  useEffect(() => {
    const hrefLangs = generateErrorPageHreflangs();
    dispatch(setHrefLangData(hrefLangs));
  }, []);
};

export default useGenerateErrorPageHreflangs;
