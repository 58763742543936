/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, useState } from 'react';

export const ExperiementContext = React.createContext([]);

const ExperiementProvider = (props) => {
  const { children } = props;
  const [variant, setVariant] = useState();

  return (
    <ExperiementContext.Provider value={[variant, setVariant]}>
      {children}
    </ExperiementContext.Provider>
  );
};

export { ExperiementProvider };

export const useExpriementVariant = () => useContext(ExperiementContext);
