/* eslint-disable camelcase */
import React from 'react';
import { HydrationWrapper } from 'views/common/components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { defaultNS } from 'next-i18next.config';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import generateHreflang from 'lib/utils/generateHreflang';
import PATHS from 'lib/navigation/paths';
import isCrawlers from 'lib/utils/isCrawlers';
import handleGeoSSR from 'views/GeoView/utils/handleGeoSSR';
import handleLocationSSR from 'views/locations-module/LocationView/utils/handleLocationSSR';
import { getDeviceType } from 'lib/utils/getDeviceType';
import { handleDetectSlugsSSR } from 'lib/utils/handleDetectSlugsSSR';
import { SLUGS_TYPES } from 'lib/utils/CONSTANTS';
import { placeSlugTypes, PLACE_TYPES } from 'views/GeoView/utils/constants';
import dynamic from 'next/dynamic';
import handleTripSSR from 'views/experience-module/MotorcycleTripView/utils/handleTripSSR';
import { handleVehicleModelPageSSR } from 'views/VehicleModelView/utils/handleVehicleModelPageSSR';
import { getLocationGeoInfo } from 'views/locations-module/LocationRentalsView/components/utils/getLocationGeoInfo';
import getLocalizedUrl from 'lib/utils/getLocalizedUrl';
import { getCookie } from 'cookies-next';
import getTranslatedSegmentSync from 'lib/navigation/getTranslatedSegmentSync';
import ErrorPage from 'views/common/components/Logical/Layout/ErrorPage';
import { ExperiementProvider } from 'views/common/context/ExperiementProvider';
import generateVMHrefLangs from 'views/VehicleModelView/utils/generateVMHrefLangs';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { useSelector } from 'react-redux';
import generateOneSlugHreflangs from 'views/common/utils/generateOneSlugHreflangs';

const GeoView = dynamic(() => import('views/GeoView').then((file) => file.GeoView));
const LocationView = dynamic(() => import('views/locations-module/LocationView').then(
  (file) => file.LocationView,
));
const MotorcycleTripView = dynamic(() => import('views/experience-module/MotorcycleTripView').then(
  (file) => file.MotorcycleTripView,
));

const VehicleModelView = dynamic(() => import('views/VehicleModelView').then((file) => file.VehicleModelView));

const isNoResultsPage = (firstSlug, secondSlug) => firstSlug?.slug_type === SLUGS_TYPES.vehicle_type
  && secondSlug?.slug === 'no-results';

const detectGeoPage = (slugs) => {
  const [firstSlug, secondSlug, thirdSlug] = slugs;

  switch (slugs.length) {
    case 1:
      if (
        [SLUGS_TYPES.vehicle_make, SLUGS_TYPES.vehicle_type].includes(
          firstSlug?.slug_type,
        )
      ) {
        return true;
      }
      break;
    case 2:
      if (
        firstSlug?.slug_type === SLUGS_TYPES.vehicle_make
        && [...placeSlugTypes, SLUGS_TYPES.vehicle_model].includes(
          secondSlug?.slug_type,
        )
      ) {
        return true;
      }
      if (
        firstSlug?.slug_type === SLUGS_TYPES.vehicle_type
        && [...placeSlugTypes].includes(secondSlug?.slug_type)
      ) {
        return true;
      }
      if (isNoResultsPage(firstSlug, secondSlug)) return true;
      break;
    case 3:
      if (
        [SLUGS_TYPES.vehicle_make, SLUGS_TYPES.vehicle_type].includes(
          firstSlug?.slug_type,
        )
        && [SLUGS_TYPES.vehicle_model].includes(secondSlug?.slug_type)
        && placeSlugTypes.includes(thirdSlug?.slug_type)
      ) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};
const getDetectSlugBody = (slugsArr) => {
  switch (slugsArr?.length) {
    case 1:
      return {
        slugs: [
          {
            slug: slugsArr[0],
            expected_types: [
              SLUGS_TYPES.vehicle_make,
              SLUGS_TYPES.vehicle_type,
              ...placeSlugTypes,
              SLUGS_TYPES.experience_page,
            ],
          },
        ],
      };
    case 2:
      return {
        slugs: [
          {
            slug: slugsArr[0],
            expected_types: [
              SLUGS_TYPES.location,
              SLUGS_TYPES.vehicle_make,
              SLUGS_TYPES.vehicle_type,

              PLACE_TYPES.MARKET,
              PLACE_TYPES.COUNTRY,
              PLACE_TYPES.REGION,
            ],
          },
          {
            slug: slugsArr[1],
            expected_types: [
              SLUGS_TYPES.vehicle_model,
              SLUGS_TYPES.vehicle_make,
              SLUGS_TYPES.vehicle_type,
              ...placeSlugTypes,
            ],
          },
        ],
      };
    case 3:
      return {
        slugs: [
          {
            slug: slugsArr[0],
            expected_types: [SLUGS_TYPES.vehicle_make, SLUGS_TYPES.location],
          },
          {
            slug: slugsArr[1],
            expected_types: [
              SLUGS_TYPES.vehicle_model,
              SLUGS_TYPES.vehicle_make,
            ],
          },
          {
            slug: slugsArr[2],
            expected_types: [...placeSlugTypes, SLUGS_TYPES.vehicle_model],
          },
        ],
      };
    default:
      return null;
  }
};
const SlugsEntry = (props) => {
  const {
    serverData, slugSSR, serverError, initWidgetData,
  } = props;

  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  const appNavigator = useAppNavigator();
  // const [slug, loadingSLug, handleSlugTypeChange, slugResponse] = useDetectSlugsCSR(
  //   slugSSR,
  //   appNavigator.query.slug_1,
  //   getDetectSlugBody(slugsArr),
  //   PATHS.geoSlug,
  //   [appNavigator.query],
  //   true,
  // );

  const renderPage = () => {
    if (serverError) {
      return <ErrorPage statusCode={serverError.statusCode} />;
    }
    const { slugs } = slugSSR;
    const shouldRenderGeoPage = detectGeoPage(slugs);
    if (shouldRenderGeoPage) {
      return (
        <ExperiementProvider>
          <GeoView
            serverData={serverData}
            slugData={slugSSR}
            isNoResultsPage={isNoResultsPage(slugs[0], slugs[1])}
          />
        </ExperiementProvider>
      );
    }
    if (!slugs) return <></>;
    switch (slugs.length) {
      case 1:
        if (slugs[0].slug_type === SLUGS_TYPES.experience_page) {
          return (
            <MotorcycleTripView serverData={serverData} slugData={slugSSR} />
          );
        }
        if (slugs[0].slug_type === SLUGS_TYPES.location) {
          return (
            <LocationView
              serverData={serverData}
              slugData={slugSSR}
              initWidgetData={initWidgetData}
            />
          );
        }
        if (
          placeSlugTypes.includes(slugs[0].slug_type)
          && slugs[0].slug_type !== SLUGS_TYPES.location
        ) {
          appNavigator.push(PATHS.geoSlug('motorcycle-rental', slugs[0].slug));
          return <></>;
        }
        break;
      case 2:
        if (
          slugs[0].slug_type === SLUGS_TYPES.location
          && slugs[1].slug_type === SLUGS_TYPES.vehicle_model
        ) {
          return (
            <VehicleModelView
              slugs={slugSSR}
              serverData={serverData}
              initWidgetData={initWidgetData}
              //   handleSlugTypeChange={handleSlugTypeChange}
            />
          );
        }
        if (
          [
            SLUGS_TYPES.location,
            PLACE_TYPES.MARKET,
            PLACE_TYPES.REGION,
            PLACE_TYPES.COUNTRY,
          ].includes(slugs[0].slug_type)
          && [SLUGS_TYPES.vehicle_make, SLUGS_TYPES.vehicle_type].includes(
            slugs[1].slug_type,
          )
        ) {
          appNavigator.push(PATHS.geoSlug(slugs[1].slug, slugs[0].slug));
          return <></>;
        }
        break;
      case 3:
        if (
          slugs[0].slug_type === SLUGS_TYPES.location
          && slugs[1].slug_type === SLUGS_TYPES.vehicle_make
          && slugs[2].slug_type === SLUGS_TYPES.vehicle_model
        ) {
          return (
            <VehicleModelView
              slugs={slugSSR}
              serverData={serverData}
              //   handleSlugTypeChange={handleSlugTypeChange}
              initWidgetData={initWidgetData}
            />
          );
        }
        break;
      default:
        break;
    }
    return (
      <>
        <ErrorPage statusCode={404} />
      </>
    );
  };

  if (!slugSSR) return <></>;

  if (isCrawler) {
    return (
      <>
        <HydrationWrapper>{renderPage()}</HydrationWrapper>
      </>
    );
  }
  return <>{renderPage()}</>;
};

export async function getServerSideProps(ctx) {
  const {
    req, res, query, locale,
  } = ctx;

  const isCrawler = isCrawlers(req.headers['user-agent']);
  const ssrRender = isCrawler;
  let serverError = null;
  let { slug_1, slug_2, slug_3 } = query;
  if (req.url.includes('DYNAMIC_PAGES')) {
    const currentUrl = getCookie('fe_geo_url', { req, res });
    if (
      (currentUrl?.startsWith(`/${locale}/${locale}/`) && slug_1 !== locale)
      || (currentUrl?.startsWith(`/${locale}/${locale}`)
        && slug_1 === 'no-results')
    ) {
      slug_3 = slug_2;
      slug_2 = slug_1;
      slug_1 = locale;
    }
  }
  const slugsArr = [slug_1, slug_2, slug_3].filter((item) => !!item);
  let hreflangData = null;
  let translationFiles = [
    'fe_er_auth',
    'fe_er_auth_overlay',
    'fe_er_billing_address',
    'fe_er_checkout_page',
    'fe_er_common_address',
    'fe_er_common_bike_card',
    'fe_er_common_bike_technical_details',
    'fe_er_common_circal_map',
    'fe_er_common_detailed_rating',
    'fe_er_common_eagle_share_promo',
    'fe_er_common_grids_gallery',
    'fe_er_common_main_widget',
    'fe_er_common_main_widget_dialog',
    'fe_er_common_membership_overlay',
    'fe_er_common_more_about_you',
    'fe_er_common_rental_card',
    'fe_er_common_tour_card',
    'fe_er_deal_card',
    'fe_er_expandable_description_component',
    'fe_er_geo_page',
    'fe_er_location_nearby_geographies',
    'fe_er_location_page',
    'fe_er_motorcycle_trip_page',
    'fe_er_my_review',
    'fe_er_reviews_card',
    'fe_er_reviews_title',
    'fe_er_share_component',
    'fe_er_vehicle_model_page',
    'fe_er_common_off_road_promotion_banner',
  ];
  let pageRes = {};
  let slugSSR = null;
  const options = {
    showAiChat: false,
    skipLayoutFromGlobal: true,
  };

  hreflangData = generateHreflang(PATHS.geoSlug(slugsArr).url, ctx.locale);
  const detectSlugBody = getDetectSlugBody(slugsArr);
  const {
    renderErrorPage,
    newPath,
    slugSSR: slugSSRDS,
    slugRes,
  } = await handleDetectSlugsSSR(ctx, detectSlugBody, PATHS.geoSlug);
  slugSSR = slugSSRDS;
  if (
    renderErrorPage
    && (slugRes?.slugs?.[1]?.slug === 'no-results'
      || slugRes?.slugs?.[0]?.slug === 'no-results')
  ) {
    res.setHeader('X-Robots-Tag', 'noindex');
    return {
      props: {
        ...(await serverSideTranslations(mapNamedLocaleToLocale[ctx.locale])),
        slugSSR: slugRes,
        slugsArr,
      },
    };
  }
  if (renderErrorPage) {
    ctx.res.statusCode = 404;
    serverError = {
      statusCode: 404,
    };
    return {
      props: {
        ...(await serverSideTranslations(mapNamedLocaleToLocale[ctx.locale])),
        serverError,
      },
    };
  }

  if (newPath) {
    return {
      redirect: {
        destination: newPath,
        permanent: true,
      },
    };
  }

  const { slugs } = slugSSR;

  switch (slugs.length) {
    case 1:
      if (
        placeSlugTypes.includes(slugs[0].slug_type)
        && slugs[0].slug_type !== SLUGS_TYPES.location
      ) {
        return {
          redirect: {
            destination: getLocalizedUrl(
              `/${encodeURIComponent(
                getTranslatedSegmentSync('motorcycle-rental', locale),
              )}/${encodeURIComponent(slugs[0].slug)}`,
              locale,
            ),
            permanent: true,
          },
        };
      }
      break;
    case 2:
      if (
        [
          SLUGS_TYPES.location,
          PLACE_TYPES.MARKET,
          PLACE_TYPES.REGION,
          PLACE_TYPES.COUNTRY,
        ].includes(slugs[0].slug_type)
        && [SLUGS_TYPES.vehicle_make, SLUGS_TYPES.vehicle_type].includes(
          slugs[1].slug_type,
        )
      ) {
        return {
          redirect: {
            destination: getLocalizedUrl(
              `/${encodeURIComponent(slugs[1].slug)}/${encodeURIComponent(
                slugs[0].slug,
              )}`,
              locale,
            ),
            permanent: true,
          },
        };
      }
      break;
    default:
      break;
  }

  const shouldRenderGeoPage = detectGeoPage(slugs);

  if (shouldRenderGeoPage) {
    hreflangData = null;

    translationFiles = [
      'fe_er_geo_page',
      'fe_er_common_eagle_share_promo',
      'fe_er_common_bike_card',
      'fe_er_common_main_widget',
      'fe_er_common_main_widget_dialog',
      'fe_er_common_membership_overlay',
      'fe_er_common_more_about_you',
      'fe_er_auth',
      'fe_er_billing_address',
      'fe_er_checkout_page',
    ];
  }
  switch (slugs.length) {
    case 1:
      if (slugs[0].slug_type === SLUGS_TYPES.location) {
        translationFiles = [
          'fe_er_location_page',
          'fe_er_deal_card',
          'fe_er_common_main_widget',
          'fe_er_common_tour_card',
          'fe_er_common_rental_card',
          'fe_er_expandable_description_component',
          'fe_er_reviews_title',
          'fe_er_common_circal_map',
          'fe_er_location_nearby_geographies',
          'fe_er_common_main_widget_dialog',
          'fe_er_common_off_road_promotion_banner',
        ];
      } else if (slugs[0].slug_type === SLUGS_TYPES.experience_page) {
        translationFiles = [
          'fe_er_location_page',
          'fe_er_deal_card',
          'fe_er_common_main_widget',
          'fe_er_common_tour_card',
          'fe_er_common_rental_card',
          'fe_er_expandable_description_component',
          'fe_er_reviews_title',
          'fe_er_common_circal_map',
          'fe_er_motorcycle_trip_page',
          'fe_er_common_tour_card',
          'fe_er_common_grids_gallery',
          'fe_er_share_component',
          'fe_er_common_off_road_promotion_banner',
        ];
      }

      break;
    case 2:
      if (
        slugs[0].slug_type === SLUGS_TYPES.location
        && slugs[1].slug_type === SLUGS_TYPES.vehicle_model
      ) {
        translationFiles = [
          'fe_er_vehicle_model_page',
          'fe_er_common_address',
          'fe_er_common_bike_technical_details',
          'fe_er_common_main_widget',
          'fe_er_my_review',
          'fe_er_common_detailed_rating',
          'fe_er_reviews_card',
          'fe_er_auth_overlay',
          'fe_er_auth',
          'fe_er_share_component',
          'fe_er_reviews_title',
        ];
      }
      if (
        slugs[0].slug_type === SLUGS_TYPES.location
        && (slugs[1].slug_type === SLUGS_TYPES.vehicle_type
          || slugs[1].slug_type === SLUGS_TYPES.vehicle_make)
      ) {
        pageRes = await getLocationGeoInfo(ctx, slugs[0].slug);

        let destination = null;
        if (pageRes.redirectURL) {
          destination = getLocalizedUrl(pageRes.redirectURL, locale);
        }
        if (pageRes.serverData?.location) {
          const { location } = pageRes.serverData;
          let geoUrl = `/${slugs[1].slug}`;
          geoUrl += location.geography?.slug
            ? `/${location.geography?.slug}`
            : `?lat=${location.latitude}&lng=${location.longitude}`;
          destination = getLocalizedUrl(geoUrl, locale);
        }
        if (destination) {
          return { redirect: { destination, permanent: true } };
        }
      }
      break;
    case 3:
      if (
        slugs[0].slug_type === SLUGS_TYPES.location
        && slugs[1].slug_type === SLUGS_TYPES.vehicle_make
        && slugs[2].slug_type === SLUGS_TYPES.vehicle_model
      ) {
        translationFiles = [
          'fe_er_vehicle_model_page',
          'fe_er_common_address',
          'fe_er_common_bike_technical_details',
          'fe_er_common_main_widget',
          'fe_er_my_review',
          'fe_er_common_detailed_rating',
          'fe_er_reviews_card',
          'fe_er_auth_overlay',
          'fe_er_auth',
          'fe_er_share_component',
          'fe_er_reviews_title',
        ];
      }
      break;
    default:
      break;
  }
  if (!pageRes.serverError) {
    if (shouldRenderGeoPage) {
      pageRes = await handleGeoSSR(ctx, slugSSR, isCrawler);
      if (
        pageRes?.serverData?.rentals.length === 0
        && (isCrawler || !req.url.includes('DYNAMIC_PAGES'))
      ) {
        return {
          redirect: {
            destination: getLocalizedUrl(
              `/${encodeURIComponent(
                getTranslatedSegmentSync('motorcycle-rental', locale),
              )}/no-results`,
              locale,
            ),
            permanent: true,
          },
        };
      }
    }
    switch (slugs.length) {
      case 1:
        if (slugs[0].slug_type === SLUGS_TYPES.location) {
          pageRes = await handleLocationSSR(ctx, slugSSR);
        } else if (slugs[0].slug_type === SLUGS_TYPES.experience_page) {
          pageRes = await handleTripSSR(ctx, slugSSR);
          hreflangData = generateOneSlugHreflangs(
            req.url,
            locale,
            pageRes.serverData.experience_page?.slug_translations,
          );
        }
        break;
      case 2:
        if (
          slugs[0].slug_type === SLUGS_TYPES.location
          && slugs[1].slug_type === SLUGS_TYPES.vehicle_model
        ) {
          pageRes = await handleVehicleModelPageSSR(
            ctx,
            {
              location_id: slugSSR.slugs[0].resource_id,
              vehicle_model_id: slugSSR.slugs[1].resource_id,
            },
            slugs,
          );
          if (pageRes.newPath) {
            return {
              redirect: {
                destination: pageRes.newPath,
                permanent: true,
              },
            };
          }
          if (pageRes.serverData) {
            hreflangData = generateVMHrefLangs(
              pageRes.serverData,
              locale,
              `/${slugSSR.slugs[0].slug}/${slugSSR.slugs[1].slug}`,
            );
          }
        }
        break;
      case 3:
        if (
          slugs[0].slug_type === SLUGS_TYPES.location
          && slugs[1].slug_type === SLUGS_TYPES.vehicle_make
          && slugs[2].slug_type === SLUGS_TYPES.vehicle_model
        ) {
          pageRes = await handleVehicleModelPageSSR(ctx, {
            location_id: slugSSR.slugs[0].resource_id,
            vehicle_make_id: slugSSR.slugs[1].resource_id,
            vehicle_model_id: slugSSR.slugs[2].resource_id,
          });
          if (pageRes.newPath) {
            return {
              redirect: {
                destination: pageRes.newPath,
                permanent: true,
              },
            };
          }
          if (pageRes.serverData) {
            hreflangData = generateVMHrefLangs(
              pageRes.serverData,
              locale,
              `/${slugs[0].slug}/${slugs[1].slug}/${slugs[2].slug_type}`,
            );
          }
        }
        break;
      default:
        break;
    }
    if (pageRes.redirect) {
      return pageRes;
    }
    if (pageRes.serverError) {
      ctx.res.statusCode = pageRes.serverError.statusCode;
      serverError = {
        statusCode: pageRes.serverError.statusCode,
      };
    } else if (!pageRes.serverData) {
      ctx.res.statusCode = 404;
      serverError = {
        statusCode: 404,
      };
    }
  }

  let deviceType = '';
  deviceType = getDeviceType(ctx.req.headers['user-agent']);

  return {
    props: {
      ...(await serverSideTranslations(mapNamedLocaleToLocale[ctx.locale], [
        ...defaultNS,
        ...translationFiles,
      ])),
      hreflangData,
      ...pageRes,
      ssrRender,
      deviceType,
      slugSSR,
      serverError,
      options,
      slugsArr,
    },
  };
}
export default SlugsEntry;
